export default [
  {
    path: '/warehouse/collect',
    name: 'warehouse-collect',
    component: () => import('../views/warehouse/collect.vue')
  },
  {
    path: '/warehouse/issue',
    name: 'warehouse-issue',
    component: () => import('../views/warehouse/issue.vue')
  },
  {
    path: '/warehouse/returnorder',
    name: 'warehouse-returnorder',
    component: () => import('../views/warehouse/returnorder.vue')
  },
  {
    path: '/warehouse/instok',
    name: 'warehouse-instok',
    component: () => import('../views/warehouse/instok.vue')
  },

]
