<template>
  <div>
   <Topheader />
   <Not />
   <Suc />
   <div class="contentblock">
    <Suspense>
          <slot />
    </Suspense>
   </div>
   <transition name="slimeright">
                   <slotokno :close="() => oknoUser(false)" :text="'Закрыть профиль'" v-if="viewUser.view"><clientmenu /></slotokno>
   </transition>
   <transition name="slimeright">
                   <slotokno :close="() => closeOknoComponent()" :text="'Закрыть'" v-if="viewOknoComponent.view">
                    <Suspense>
                    <component :is="viewOknoComponent.component" />
                    </Suspense>
                  </slotokno>
   </transition>

   <transition name="slimeright">
                   <pwaupdate v-if="viewPwa" />
   </transition>

  </div>

</template>

<script setup>
import Topheader from '@/components/general/topheader.vue'
import Not from '@/components/general/not.vue'
import Suc from '@/components/general/suc.vue'
import { viewPwa, viewOknoComponent, closeOknoComponent } from '@/controlles/general'
import { viewUser, oknoUser } from '@/controlles/client'
import clientmenu from '@/components/client/clientmenu.vue'
import pwaupdate from '@/components/general/pwaupdate.vue'
import {connectSse} from '@/sse'
connectSse()

</script>

<style scoped>

.contentblock{
  margin-top: 70px;
}
</style>
