import { ref } from 'vue'
import { role, user } from '@/controlles/auth'
import axios from '@/utils/axios'
import { noloading } from '@/controlles/general'

const state = ref([])
const online = ref(false)
const reconumber = ref(0)
let reconnect = null


const getNotification = async () => {
    try{
        noloading.value = true
        var data = await axios.post('https://api.ultimatum.store/bd',{
            collection:'armnot',
            filter:{
                status:false,
                role:role.value
            }
        })
        noloading.value = false
        state.value = data.data
    }catch(e){
        noloading.value = false
        console.log(e)
    }
}

const connectSse = async () => {
    const eventSource = new EventSource(`https://api.ultimatum.store/arm/sse/connect?id=${user.value._id}&role=${role.value}`);

    eventSource.onopen = () => {
        console.log('SSE connection opened');
        online.value = true;
        if (reconnect) {
            clearInterval(reconnect);
            reconnect = null;
        }
    };

    eventSource.onmessage = (event) => {
        try {
            const data = JSON.parse(event.data);
            switch (data.event) {
                case 'connect':
                    online.value = true;
                    getNotification()
                    break;
                case 'notification':
                    getNotification()
                    break;
            }
        } catch (error) {
            console.error('Error parsing event data:', error);
        }
    };

    eventSource.onerror = (error) => {
        online.value = false;
        console.error('EventSource error:', error);
        eventSource.close();
        if (!reconnect) {
            reconnect = setInterval(() => {
                if (online.value) {
                    clearInterval(reconnect);
                    reconnect = null;
                } else {
                    console.log('Переподключение');
                    connectSse();
                }
            }, 5000);
        }
    };
};

export {
    state,
    connectSse,
    online
};